<template>
  <b-card no-body class="card-profile" alt="Image placeholder" img-top>
    <b-row class="justify-content-center">
      <b-col lg="3" class="order-lg-2">
        <div class="card-profile-image">
          <a href="#">
            <b-img :src="user.logoURL" data-target="#imageCropModalOrg" v-b-hover="handleHover" @error="imgError" ref="imageRef" rounded="circle"  />
             
          </a>
        </div>
      </b-col>
    </b-row>

    <b-card-header class="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between">
        <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>
        <a href="#" class="btn btn-sm btn-default float-right">Message</a>
      </div>
    </b-card-header>

    <b-card-body class="pt-0"> 
      <div class="text-center">
        <h5 class="h3">
          {{user.firstName}} {{user.lastName}} 
        </h5>
        <div class="h5 font-weight-300">
          <i class="ni location_pin mr-2"></i>  
        </div>
        <div class="h5 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i> 
        </div>
        <div>
          <i class="ni education_hat mr-2"></i> 
        </div>
        <hr class="my-4">
        <p>{{user.companyDescription}}</p>
        <a href="#">Show more</a>

      </div>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
    computed: { 
      user() { 
        return this.$store.state.authentication.user.data
      }, 
    },
};
</script>
<style></style>
